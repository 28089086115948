<template>
  <div class="price" :class="customClass"> 
    {{ price || 0 }} RUB
  </div>
</template>

<script>
export default {
  name: 'Price',
  props: {
    price: {
      type: Number,
      required: true
    },
    customClass: {
      type: String,
      required: false
    }
  },
}
</script>

<style scoped lang='scss'>

.product_page.price {
  font-size: 2rem;
}
</style>