<template>
  <li class="card">
    <section class="card--showcase">
      <router-link :to="{ name: 'product', params: {id, slug} }">
        <img class="card--image" :src="image"  alt="thumbnail of product">
      </router-link>
      <!-- src="@/assets/products/Ашваганда_m.png" -->
      <div class="card--content">
        <router-link 
          :to="{ name: 'product', params: {id, slug} }" 
          class="card--title"
        >
          {{ title }}
        </router-link>

        <p class="card--description"> {{ packing }} {{ type }}</p>
      </div>
      <div class="delete-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="card--delete" @click="handleDelete">
          <path d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z" class="cross"/>
          <path d="M0,0H24V24H0Z" fill="none"/>
        </svg>
      </div>
    </section>
    <section class="card--adjustment" >
      <CartDetailsQuantifier 
        :quantity="quantity" 
        :id="id" 
        :packing="packing"
        class="card--quantifier"
      />
      <Price class="card--price semi-bold" :price="price * quantity" />
      <!-- <p class="">{{  }} RUR</p> -->
    </section>
  </li>
</template>

<script>
import CartDetailsQuantifier from './CartDetailsQuantifier'
import Price from '@/components/common/Price';

import { mapActions } from 'vuex'


export default {
  name: 'CartDetailsCard',
  components: {
    CartDetailsQuantifier,
    Price
  },
  props: {
    image: {
      type: String,
      // required: true,
    },
    title: {
      type: String,
      // required: true,
    },
    packing: {
      type: Number,
      // required: true,
    },
    price: {
      type: Number,
      // required: true
    },
    quantity: {
      type: Number,
      // required: true
    },
    type: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true
    },
    slug: {
      type: String
    }
  },
  computed: {
    finalPrice() {
      return this.price * this.quantity
    }
  },
  methods: {
    ...mapActions(['deleteFromCart']),
    handleDelete() {
      this.deleteFromCart({
        product: {
          id: this.id,
          packing: this.packing
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.card {

  border-bottom: 2px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 10px;

  &--title:hover {
    color: var(--main-red);
  }

  &--image {
    max-width: 73px;
    max-height: 87px;

    &:hover {
      opacity: 0.8;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &--showcase {
    display: grid;
    grid-template-columns: auto 3fr 22px;
    column-gap: 13px;
    margin-bottom: 10px;
  }

  &--adjustment {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }



  &--delete {
    fill: #fff;

    &:hover {
      cursor: pointer;
      fill: var(--main-red);
    }
  }

  &--price {
    margin-left: 20px;
    font-size: 1.25rem;
    text-align: end;
    width: 130px;
  }
}

</style>