<template>
  <button class="quantifier" >
    <div class="quantifier--content">
      {{ quantity }}
    </div>
    <div class="quantifier--buttons">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16" class="quantifier--button" @click="handleAddition">
        <g transform="translate(19.655) rotate(90)">
          <path d="M6.068,1.155,4.914,0,0,4.914,4.914,9.827,6.068,8.673,2.318,4.914Z" transform="translate(6.552 4.914)" class="arrow"/>
          <path d="M0,0H22V22H0Z" transform="translate(0 0)" fill="none"/>
        </g>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16" class="quantifier--button" @click="handleSubtraction">
        <g transform="translate(0 16) rotate(-90)">
          <path d="M6.068,1.155,4.914,0,0,4.914,4.914,9.827,6.068,8.673,2.318,4.914Z" transform="translate(6.552 4.914)" class="arrow"/>
          <path d="M0,0H22V22H0Z" transform="translate(0 0)" fill="none"/>
        </g>
      </svg>
    </div>
  </button>

</template>

<script>
import { mapActions } from 'vuex'


export default {
  name: 'CartDetailsQuantifier',
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    packing: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions(['updateCart']),

    handleSubtraction() {
      if (this.quantity === 1) return;

      this.updateCart({ product: {
        id: this.id, packing: this.packing,
        quantity: this.quantity - 1,
      }})      
    },

    handleAddition() {
      this.updateCart({ product: {
        id: this.id, packing: this.packing,
        quantity: this.quantity + 1,
      }})      
    }
  }
}
</script>

<style scoped lang='scss'>

.quantifier {
  color: white;
  background-color: var(--bg);
  border: 2px solid #fff;
  padding: 2px 5px 2px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55px;
  height: 41px;

  font-size: 18px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  line-height: 14px;

  &--content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &--buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

  }

  &--button {
    &:hover {
      cursor: pointer;
    }

    & .arrow {
      fill: #fff;
    }
    
    &:hover .arrow {
      fill: var(--main-red);
    } 
  }
}

</style>

