<template>
  <div class="input-container" >
    <input :type="type ? type : 'text'" :id="name" class="input"   
      v-model="client[name]"
      required
      :pattern="pattern"
      :maxlength="maxlength"
    >
    <label :for="name"  class="label medium">* {{ label }} </label>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CartFormInput',
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
    },
    name: {
      type: String,
      required: true
    },
    pattern: {
      type: String
    },
    maxlength: {
      type: Number,
    },
  },
  computed: {...mapState(['client'])},
}
</script>

<style lang="scss">

.input-container {
  position: relative;
  display: flex;
  height: 50px;
  margin: 30px 0;
  background-color: transparent;
  border: 2px solid #4D4D4D;
  border-radius: 25px;
}

.label {
  letter-spacing: 0.05rem;
  font-size: 0.88rem;
  position: absolute;
  top: -11px;
  left: 20px;
  background-color: var(--bg);
  padding: 0px 10px;
  color: #878787;

  &:hover {
    cursor: pointer;
  }
}

.input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  color: #fff;
  font-size: 14px;

  &:focus + label {
    color: #B2B2B2;
  }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
    font: 400 14px Arial;
}


</style>