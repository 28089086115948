import rest from './promo-rest';

/**
 *
 * @param {string} code
 * @returns {Promise<number>}
 */
export async function getPromoByCode(code) {
  const response = await fetch(rest.get_promo_by_code(code));
  return (await response.json())[0];
}

/**
 *
 * @param {string} subscribeId
 * @returns {Promise<Array>}
 */
export async function unsubscribePromoMailing(subscribeId) {
  const response = await fetch(rest.unsubscribe_promo_mailing(subscribeId));
  return response.json();
}

export async function activatePromo(success, clientId, promoId) {
  const response = await fetch(rest.activate_promo, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      promo: promoId,
      client: clientId,
      success,
    })
  });

  return response.json();
}

export async function patchActivePromo(patchInfo) {
  fetch(rest.patch_active_promo(patchInfo.id), {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patchInfo)
  }).then();
}

export async function getClientById(id) {
  try {
    const response = await fetch(rest.get_client(id));
    return response.json();
  } catch {
    return null;
  }
}
