<template>
  <div class="cart-wrapper">

    <header class="cart-header">
      <p class="cart-header--title">/ Оформление заказа</p>
    </header>

    <form class="order">

      <section class="credentials">
        <p class="order--title order--title-show" @click="showForm = !showForm" >
          1. Адрес доставки
        </p>
        <form v-if="showForm" @submit="handleInputs">
          <p class="order--error" v-if="error"> {{ error }} </p>
          <p class="order--success" v-if="success">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0,0H24V24H0Z" fill="none"/><path d="M18,7,16.59,5.59l-6.34,6.34,1.41,1.41Zm4.24-1.41L11.66,16.17,7.48,12,6.07,13.41,11.66,19l12-12ZM.41,13.41,6,19l1.41-1.41L1.83,12Z" fill="#fff"/></svg>
            <span class="success-text">{{ success }}</span>
          </p>
          <CartForm />
          <CartButton 
            text="Продолжить"
            type="submit"
          />
        </form>
      </section>

      <section>
        <p class="order--title">2. Способ доставки</p>
        <CartDelivery v-if="showDelivery"/>
        <div @click="showPaymentMethod = !showPaymentMethod">
          <CartButton 
            v-if="showDelivery" 
            text="Продолжить"
            type="button"
          />
        </div>
      </section>

      <section>
        <p class="order--title">3. Оплата</p>
        <div v-if="showPaymentMethod" class="payment">
          <ul class="payment--list">Оплата принимается переводом на карту банка или электронный кошелёк:
            <li class="payment--item">СБЕРБАНК: 4817 7601 7829 9008 (СБП +79081717444)</li>
            <li class="payment--item">Альфа-банк: 4584 4328 2295 8733 (СБП +79081717444)</li>
            <li class="payment--item">Qiwi кошелёк +79081717444</li>
            <li class="payment--item">ЮMoney кошелёк +79081717444</li>
          </ul>
          <p class="payment--info">
            При оплате укажите в комментарии номер Вашего заказа. Система автоматически присвоит заказу статус «Оплачен». Заказ будет сформирован и отправлен.
          </p>
        </div>
      </section>

      <section >
        <p class="order--title">4. Просмотр и размещение заказа</p>
        <div v-if="showPaymentMethod" class="proceed">
          <p class="proceed--text">
            Пожалуйста, проверьте детали заказа. Когда будете готовы, нажмите
            «Разместить заказ».
          </p>
          <label for="agreement">
            <input type="checkbox" name="agreement" id="agreement" v-model="isAgreed">
            Я принимаю 
            <router-link :to="{ name: 'offer'}" class="link">
              условия использования
            </router-link>
          </label>
          <label for="data">
            <input type="checkbox" name="data" id="data" v-model="personalData">
            Согласен (-на) на обработку персональных данных
          </label>
        </div>
        <div @click="handleOrder">
          <CartButton 
            v-if="showPaymentMethod" 
            text="Разместить заказ"
            isProceed
            :isDisabled="!(isAgreed && personalData && getCartLength)"
          />
        </div>

      </section>

    </form>

    <section class="cart">

      <CartDetails />

      <div class="sum">
        <CartPromo />
        <section class="sum--info">
          <span>Сумма за все товары:</span>
          <span class="sum--info-price">
            {{ cartSum ? cartSum + ' RUR' : ''}}
          </span>
        </section>
        <section class="sum--info" :class="{ 'sum--info-border': !promo.value}">
          <span>Доставка:</span>
          <span class="sum--info-price">
            {{ deliveryPrice }} RUR
          </span>
        </section>
        <section class="sum--info sum--info-border" v-if="promo.value">
          <span>Промокод:</span>
          <span class="sum--info-price sum--info-green">
            {{ promoPrice ? -promoPrice + ' RUR' : '' }}
          </span>
        </section>
        <section class="sum--info sum--info-final">
          <span class="sum--itogo">Итого:</span>
          <span class="bigFont medium">
            {{ cartSum ? (cartSum + deliveryPrice - promoPrice) + ' RUR' : '' }}
          </span>
        </section>

      </div>

      <section class="certificates">
        <img src="@/assets/certificates.png" alt="ssl certificates">
      </section>

      <section class="danger">
        <span class="danger--content">Внимание!</span>
      </section>
      <section class="problems">
        <p class="problems--content">
          Если при оформлении у вас возникли трудности, или вы допустили ошибку или у вас возникли иные вопросы, свяжитесь с нами удобным вам способом, и мы обязательно вам поможем!
        </p>
        <ul class="problems--content">
          <li class="problems--item">
            Бесплатный федеральный номер: 8 800 201 66 39
          </li>
          <li class="problems--item">
            Электронная почта: support@themantra.shop
          </li>
          <li class="problems--item">
            Whatsapp чат: +7 901 000-19-02
          </li>
        </ul>
      </section>

    </section>

  </div>
</template>



<script>
import CartForm from './CartForm';
import CartButton from './CartButton';
import CartDelivery from './CartDelivery';
import CartDetails from './CartDetails';
import CartPromo from "./CartPromo";

import API from '@/API'
import helpers from '@/helpers'
import { patchActivePromo } from "@/API/promo-api";

import { mapActions, mapState, mapGetters } from 'vuex'
import { getClientById } from "../../../API/promo-api";


export default {
  name: 'Cart',
  components: {
    CartButton,
    CartDelivery,
    CartDetails,
    CartForm,
    CartPromo
  },
  data() {
    return {
      showForm: false,
      showDelivery: false,
      showPaymentMethod: false,
      isAgreed: false,
      personalData: false,
      error: '',
      success: '',
      isExist: false,
    }
  },
  computed: {
    ...mapState(['cart', 'orderMethod', 'client', 'delivery', 'promo', 'isClientExist']),
    ...mapGetters(['getCartLength']),

    cartSum() {
      return this.cart.reduce((sum, product) => sum + (product.price * product.quantity), 0);
    },

    deliveryPrice() {
      return this.delivery === 'russian' ? 500 : 790;
    },

    promoPrice() {
      if (this.promo.value) {
        return this.promo.isPercent
          ? Math.round(this.cartSum * this.promo.value / 100)
          : this.promo.value;
      }

      return 0;
    },

    csrftoken() {
      return helpers.getCookie('csrftoken');
    }
  },
  methods: {
    ...mapActions(['setClient', 'setCart', 'setIsClientExist']),

    handleStupidClients() {
      this.error = 'Заполните анкету со своими данными, пожалуйста';
      setTimeout(() => this.error = '', 5000);
      this.showDelivery = false;
      this.showPaymentMethod = false;

    },

    async handleInputs(e) {
      e.preventDefault();

      if (!this.isClientExist) {
        this.setClient(await API.createClient(this.client, this.csrftoken));
        this.setIsClientExist(true);
      } else {
        await this.handleClientUpdate();
      }


      if (!this.client) {
        this.error = 'Почта указана неверно';
        setTimeout(() => this.error = '', 3000)
        return;
      }

      setTimeout(() => this.success = null, 3000)
      this.success = 'Поля успешно заполнены';
      this.showDelivery = !this.showDelivery;
    },
    async handleClientUpdate() {
      const defaultClient = await getClientById(this.client.id);
      const toUpdate = Object.keys(defaultClient).some((key) => this.client[key] !== defaultClient[key]);
      if (toUpdate) {
        API.updateClient(this.client).then();
      }
    },
    async handleOrder(e) {
      e.preventDefault();

      const isStupid = Object.keys(this.client).some((key) => !this.client[key]);
      if (isStupid) {
        this.handleStupidClients();
        return
      }

      const order = await API.createOrder({
        client: this.client.id,
        sum: this.cartSum,
        delivery: this.delivery,
        promoSum: -this.promoPrice,
        promo: this.promo.id ? this.promo.id : null
      }, this.csrftoken)

      if (!order) {
        // console.log('Some error', order);
        return;
      }

      if (this.promo.value) {
        patchActivePromo({
          id: this.promo.activeId,
          order: order.id,
          client: this.client.id
        }).then();
      }
      const orderList = await API.createOrderList(this.cart, order.id);
      if (!orderList) {
        // console.log('THERE HAVE BEEN SOME ERROR')
        return;
      }
      this.$router.push({
        name: 'gratitude',
        params: {
          orderId: order.id,
          total: this.cartSum + this.deliveryPrice - this.promoPrice
        }
      });

      this.setCart({cart: []});
    }
  },
}
</script>

<style scoped lang='scss'>
.order {
  grid-area: order;

  &--title {
    border-bottom: 2px solid #fff;
    padding-bottom: 11px;
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 15px;

    &-show:hover {
      cursor: pointer;
      color: var(--main-red);
    }
  }

  &--error {
    background-color: red;
    color: white;
    padding: 20px 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    text-align: center;
  }

  &--success {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2F7E15;
    text-transform: uppercase;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    padding: 9px 10px;
    margin-bottom: 20px;
    border-radius: 30px;
    text-align: center;
  }
}

.cart {
  grid-area: cart;
  display: flex;
  flex-direction: column;
}

.cart-header {
  grid-area: header;
  display: flex;
  align-items: center;
  font-size: 0.94rem;

  &--icon {
    margin-right: 20px;
  }

  &--title {
    font-size: 0.93rem;
    font-weight: 600;
  }
}


.cart-wrapper {
  display: grid;
  grid-template-areas:
                      '. header header header .'
                      '. order . cart .';
  grid-template-columns: 3% 1.1fr 8% 0.8fr 3%;
  grid-template-rows: 60px auto;
  font-family: 'Montserrat';
}

.payment {
  font-size: 15px;

  &--item {
    margin: 10px 0;
    letter-spacing: 0.01rem;
  }
}

.proceed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
  margin-bottom: 20px;

  &--text {
    line-height: 200%;
  }
}

.link {
  text-decoration: underline;
}



.sum {

  &--promo {
    display: flex;
    align-items: center;
    width: 215px;
    justify-content: space-between;
    margin: 28px 0;
  }

  &--itogo {
    font-weight: 600;
    text-transform: uppercase;
  }

  &--info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 23px;

    &-border {
      border-bottom: 1px solid #C2C2C2;
    }

    &-final {
      margin-top: 17px;
    }

    &-green {
      color: #00ff3c;
    }

    &-price {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}

.certificates {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.danger {

  margin-top: 19px;
  margin-bottom: 21px;

  &--content {
    color: red;
    font-size: 1.56rem;
    font-weight: 600;
  }
}

.problems {

  &--content {
    margin-bottom: 40px;
  }

  &--item {
    margin-bottom: 10px;
  }
}

.bigFont {
  font-size: 32px;
}

.success-text {
  margin-left: 5px;
}


@media screen and (max-width: 520px) {
  .cart-wrapper {
    display: grid;
    grid-template-areas:
                        '. header header header .'
                        '. order order order .'
                        '. cart cart cart .';
    grid-template-columns: 3% 1.1fr 8% 0.8fr 3%;
    grid-template-rows: 60px auto auto;
    font-family: 'Montserrat';
  }
}


</style>