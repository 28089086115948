<template>
  <div>
    <div class="cart-promo__error" v-if="error">{{ error }}</div>
    <div class="cart-promo">
      <div class="cart-promo__label-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="30.065" height="30.573"
             class="cart-promo__icon" viewBox="0 0 30.065 30.573">
          <path d="M30.414.149a.509.509,0,0,0-.721,0L27.8,2.038H15.941L1.025,17.076a1.8,1.8,0,0,0,0,2.543L11.454,30.048A1.8,1.8,0,0,0,14,30.043L28.525,15.127V2.759L30.414.87A.509.509,0,0,0,30.414.149ZM27.506,14.713,13.277,29.327a.78.78,0,0,1-1.1,0L1.746,18.9a.78.78,0,0,1,0-1.1L16.366,3.057h10.42L24.323,5.52a2.555,2.555,0,1,0,.721.721l2.463-2.463Zm-3.057-7.07A1.529,1.529,0,1,1,22.92,6.115a1.511,1.511,0,0,1,.655.153L22.56,7.283A.509.509,0,1,0,23.28,8L24.3,6.989A1.5,1.5,0,0,1,24.449,7.643Z" transform="translate(-0.499 0)" fill="#fff"/>
        </svg>
        <span class="cart-promo__label">Добавить промокод</span>
      </div>
      <input v-model="promoCode" @change="checkPromoCode" type="text" class="cart-promo__input" maxlength="10">
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import { getPromoByCode } from "@/API/promo-api";
import promoErrors from "@/errors/promo.error";
import { activatePromo } from "../../../API/promo-api";

export default {
  name: "CartPromo",
  data() {
    return {
      error: '',
    }
  },
  computed: {
    promoCode: {
      get() {
        return this.$store.state.promo.code;
      },
      set(code) {
        this.$store.commit('setPromo', { code });
      }
    },
    ...mapState(['statePromoCode', 'client', 'promo'])
  },
  methods: {
    ...mapActions(['setPromo']),
    async checkPromoCode(e) {
      const code = e.target.value.toUpperCase();
      const promo = await getPromoByCode(code);

      if (!promo) return;

      const activePromo = await activatePromo(promo.is_active, this.client.id, promo.id);
      if (promo.is_active) {
        this.setPromo({ ...promo, activeId: activePromo.id });
        return;
      }

      if (!this.error) {
        setTimeout(() => {
          this.error = '';
        }, 4000);
        this.error = promoErrors.IS_EXPIRED;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.cart-promo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;

  &__label-container {
    display: flex;
    align-items: center;
  }

  &__label {
    letter-spacing: var(--letter-spacing-main);
  }

  &__icon {
    margin-right: 16px;
    min-width: 31px;
  }

  &__input {
    max-width: 133px;
    height: 35px;
    border: 2px solid #4d4d4d;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    letter-spacing: 0.01rem;
    font-weight: 500;
    color: #b4b4b4;
    padding: 9px 16px;
    text-align: right;
    text-transform: uppercase;
  }

  &__error {
    background-color: red;
    color: white;
    padding: 20px 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    text-align: center;
  }
}

@media (max-width: 950px) {
  .cart-promo {
    flex-direction: column;

    &__label-container {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 520px) {
  .cart-promo {
    flex-direction: row;

    &__label-container {
      margin-bottom: 0;
    }
  }
}
</style>