export const ALL_COUNTRIES = [
  "azerbaijan",
  "armenia",
  "belarus",
  "kazakhstan",
  "kyrgyzstan",
  "moldova",
  "russia",
  "tajikistan",
  "turkmenistan",
  "uzbekistan",
  "ukraine",
];
