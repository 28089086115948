<template>
  <div class="method medium">
    <div>
      <label class="method--radio">
        <input 
          type="radio" 
          name="method" 
          :checked="value === delivery"
          @click="handleDelivery"
        />
        <span class="method--checkmark"></span>
      </label>
    </div>
    <div class="method--title">
      <span>{{ title }}</span>
      <span>{{ duration }}</span>
    </div>
    <div class="method--icon">
      <img src="@/assets/Russian_Post_logo.png" v-if="icon" />
    </div>
    <div class="method--price">{{ price }} RUR</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CartDeliveryInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    duration: {
      type: String,
    },
    icon: {
      type: Boolean,
    },
    price: {
      type: String,
    },
  },
  computed: {...mapState(['delivery'])},
  methods: {
    ...mapActions(['toggleDelivery']),
    handleDelivery() {
      this.toggleDelivery({delivery: this.value})
    }
  },
}
</script>

<style scoped lang="scss">

.method {
  // background-color: #fff;
  // color: black;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  height: 50%;
  border: 1px solid #A5A5A5;
  padding: 7px;


  &--title {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 310px;
  }

  &--icon {
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.method--radio {
  display: block;
  position: relative;
  margin: 0px 10px 30px 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.method--radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.method--checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #707070;
}

/* On mouse-over, add a grey background color */
.method--radio:hover input ~ .method--checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

/* Create the indicator (the dot/circle - hidden when not checked) */
.method--checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.method--radio input:checked ~ .method--checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.method--radio .method--checkmark:after {
  top: 3px;
  left: 3px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #000;
}

@media (max-width: 1100px) {
  .method {

    flex-direction: column;

    &--icon {
      display: none;
    }

    &--title {
      align-items: center;
    }
  }
}


</style>