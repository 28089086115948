<template>
  <section>
    <div  @change="fetchClientByEmail">
      <CartFormInput 
        name="email" 
        label="Эл. почта для подтверждения заказа" 
        type="email"
        class="mobile-big"
      />
    </div>

    <CartFormInput name="name" label="Имя" :maxlength="255"/>

    <CartFormInput name="surname" label="Фамилия" :maxlength="255" />

    <div class="small-inputs">
      <div class="input-container select-container small-input small-input_bigger small-input_location">
        <label class="label">* Страна</label>
        <select 
          required 
          v-model="client.country" 
          class="input select" 
        >
          <option disabled></option>
          <option value="azerbaijan">Азербайджан</option>
          <option value="armenia">Армения</option>
          <option value="belarus">Беларусь</option>
          <option value="kazakhstan">Казахстан</option>
          <option value="kyrgyzstan">Кыргызстан</option>
          <option value="moldova">Молдова</option>
          <option value="russia">Россия</option>
          <option value="tajikistan">Таджикистан</option>
          <option value="turkmenistan">Туркменистан</option>
          <option value="uzbekistan">Узбекистан</option>
          <option value="ukraine">Украина</option>
        </select>
      </div>
      <CartFormInput 
        name="city" 
        label="Город" 
        class="small-input small-input_smaller small-input_location"
        :maxlength="255"
      />
    </div>

    <CartFormInput name="address" label="Адрес." />

    <div class="small-inputs">
      <CartFormInput 
        name="index" 
        label="Почтовый индекс"
        :maxlength="12"
        class="small-input small-input_bigger"
      />
      <CartFormInput 
        name="phone" 
        label="Телефон." 
        type="tel" 
        :maxlength="15" 
        class="small-input small-input_smaller"
      />
    </div>
    
  </section>
</template>

<script>
import CartFormInput from './CartFormInput'
import { mapActions, mapState } from 'vuex'
import API from '@/API'
import { DeliveryType } from "@/constants/enums/delivery.enum";

export default {
  name: 'CartForm',
  components: {
    CartFormInput,
  },
  watch: {
    'client.country': function() {
      const delivery = (this.client.country === 'russia') ? DeliveryType.RUSSIAN : DeliveryType.SNG;
      this.toggleDelivery({ delivery })
    }
  },
  computed: {...mapState(['client', 'country'])},
  methods: {
    ...mapActions(['setClient', 'changeCountry', 'toggleDelivery', 'setIsClientExist']),
    async fetchClientByEmail() {

      if (!this.client.email) {
        return;
      }

      const clientByEmail = await API.getClientByEmail(this.client.email);

      if (clientByEmail.length) {
        this.setClient(clientByEmail[0]);
        this.setIsClientExist(true);
      }
    },
  }
}
</script>

<style scoped lang="scss">

.select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0 0;
}

.location {
  display: flex;
}

.select {
  & option {
    background-color: var(--bg);
  }
}

.small-inputs {
  display: flex;

  .small-input {
    margin: 0 0 15px 0;
    min-height: 45px;
    
      &_bigger {
        flex: 1.3;
      }

      &_location {
        margin: 0;
      }

      &_smaller {
        flex: 1;
        margin-left: 25px;
      }
  }

}

@media screen and (max-width: 800px) {
  .small-inputs {
    flex-direction: column;

    & .input-container {
      margin: 15px 0;
    }
  }
}

@media screen and (max-width: 410px) {
  .mobile-big {
    height: 60px;
  }
}

</style>