<template>
  <section>
    <header class="header">
      <p class="header--details medium">Детали заказа ({{ getCartLength }})</p>
    </header>
    <ul class="cards">
      <CartDetailsCard 
        v-for="product in cart"
        :key="product.id && product.packing"
        :title="product.title"
        :image="product.thumb"
        :packing="product.packing"
        :price="product.price"
        :quantity="product.quantity"
        :type="product.type"
        :id="product.id"
        :slug="product.slug"
      />
    </ul>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CartDetailsCard from './CartDetailsCard'

export default {
  name: 'CartDetails',
  components: {
    CartDetailsCard,
  },
  computed: {...mapGetters(['getCartLength']), ...mapState(['cart'])},
}
</script>

<style scoped lang="scss">

.header {

  margin-bottom: 13px;
  
  &--details {
    font-size: 0.88rem;
  }
}

.cards {
  border-top: 2px solid #fff;
  padding: 13px 0;
}

</style>