<template>
  <div class="method-container">
    <template v-if="client.country === 'russia'">
      <CartDeliveryInput
        :value="DeliveryType.RUSSIAN"
        title="Доставка Почта России 1 класс (экспресс)"
        duration="4-12 дней"
        :icon="true"
        price="500"
      />
      <CartDeliveryInput
        :value="DeliveryType.SDEK"
        title="Курьерская служба СДЕК"
        duration="2-7 дней"
        :icon="false"
        price="590"
      />
    </template>
    <CartDeliveryInput
      v-else-if="ALL_COUNTRIES.includes(client.country)"
      :value="DeliveryType.SNG"
      title="Доставка по СНГ ТОЛЬКО "
      duration="Почта России международное отправление"
      :icon="false"
      price="590"
      class="full"
    />
    <div v-else>
      <h1>Выберите, пожалуйста, страну для показа метода доставки.</h1>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { ALL_COUNTRIES } from "@/constants/global-constants/countries";
import { DeliveryType } from "@/constants/enums/delivery.enum";
import CartDeliveryInput from './CartDeliveryInput';

export default {
  name: 'CartDelivery',
  components: {
    CartDeliveryInput,
  },
  data() {
    return {
      ALL_COUNTRIES,
      DeliveryType
    }
  },
  computed: {...mapState(['client'])}
}
</script>

<style scoped lang="scss">

.full {
  height: 100%;
}

.method-container {
  height: 150px;
  margin: 30px 0 10px 0;

  @media (max-width: 1100px) {
    height: 200px;
  }
}
</style>