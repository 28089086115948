<template>
  <button 
    class="cart-button medium" 
    :class="{ 
      'proceed-button bold': isProceed,
      'disabled': isDisabled
    }"
    :disabled="isDisabled"
    :type="type"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'CartButton',
  props: {
    text: {
      type: String,
      required: true
    },
    isProceed: {
      type: Boolean
    },
    isDisabled: {
      type: Boolean
    },
    type: {
      type: String,
    }
  }
}
</script>

<style scoped lang='scss'>

.cart-button {
  background-color: #4B4B4B;
  width: 100%;
  height: 45px;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Montserrat';
  margin: 10px 0 20px 0;
  font-size: 0.88rem;
  letter-spacing: 0.09rem;
  border-radius: 30px;

  &:hover {
    cursor: pointer;
    background-color: #000;
  }
}

.proceed-button {
  background-color: #1FDC71;
  color: #000000;

  &:hover {
    background-color: #fff;
  }
}

.disabled {
  opacity: 0.1;

  &:hover {
    cursor: default;
    color: #000;
    background-color: #1FDC71;
  }
}

</style>